import React from "react"
import Search from "./Search"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Sidebar = ({ className }) => (
  <div
    className={`${className} flex flex-col bg-color-secondary w-72 items-center overflow-auto`}
  >
    <Link to="/">
      <StaticImage
        className="w-36 mb-2"
        src="../images/logo_grey-green.png"
        alt="Sharpen Up! Logo"
      />
    </Link>
    <div className="flex flex-1 flex-col m-1">
      <h4 className="font-display text-color-primary-lightened text-xl text-center">
        Sharpen Up! on YouTube
      </h4>
      <div className="flex flex-col mb-10 items-center justify-center  rounded-3xl relative bottom-4">
        <a
          href="https://www.youtube.com/channel/UCXUCYHvVP2rEHjMkEO-O2Mg"
          target="_blank"
          rel="noreferrer"
          className="w-36"
          title="YouTube"
        >
          <StaticImage
            src="../images/youtube-social-icon.svg"
            alt="YouTube icon"
          />
        </a>
        <h5 className="font-display text-white text-lg text-center">
          Click on the big red button for video tutorials
        </h5>
      </div>
      <div className="relative bottom-4">
        <h4 className="font-display text-color-primary-lightened text-xl text-center">
          Search our post archive
        </h4>
        <Search />
      </div>
    </div>
    <div className="m-4">
      <h4 className="font-display text-color-primary-lightened text-lg text-center">
        Stay up to date with Sharpen Up!
      </h4>
      <div className="flex flex-row items-center justify-center">
        <a
          href="https://twitter.com/sharpenup_yt"
          target="_blank"
          rel="noreferrer"
          className="m-1 w-10"
          title="Twitter"
        >
          <StaticImage
            src="../images/twitter-social-icon.svg"
            alt="Twitter icon"
          />
        </a>

        <a
          href="https://github.com/programmerinprogress"
          target="_blank"
          rel="noreferrer"
          className="m-1 w-10"
          title="GitHub"
        >
          <StaticImage
            src="../images/github-social-icon.svg"
            alt="Github icon"
          />
        </a>

        <a href="mailto:mail@sharpenup.io" className="m-1 w-10" title="E-mail">
          <StaticImage
            className="h-10 rounded-md"
            src="../images/mail-social-icon.svg"
            alt="Email icon"
          />
        </a>
      </div>
    </div>
  </div>
)

export default Sidebar
