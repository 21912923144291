import React, { Component } from "react"
import { Link } from "gatsby"

class Search extends Component {
  state = {
    query: "",
    results: [],
  }

  render() {
    return (
      <div className="py-2 px-7">
        <input
          className="w-full p-2 text-lg"
          type="text"
          value={this.state.query}
          onChange={event => {
            this.search(event)
          }}
          placeholder={"Search posts"}
        />
        <ul className="my-2">
          {this.state.results.length === 0 && this.state.query.length > 0 ? (
            <li className="text-color-accent hover:text-color-primary-lightened text-lg font-display text-center">
              Search returned no results for "{this.state.query}"
            </li>
          ) : null}
          {this.state.results.length > 0 ? (
            <li className="text-color-accent hover:text-color-primary-lightened text-lg font-display text-center">
              Blogs found:
            </li>
          ) : null}
          {this.state.results.map(page => (
            <li key={page.url}>
              <Link
                className="text-color-accent hover:text-color-primary-lightened text-lg font-display"
                to={page.url}
              >
                -&nbsp;{page.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  getSearchResults(query) {
    if (!query || !window.__LUNR__) return []
    const results = window.__LUNR__.en.index.search(query)
    return results.map(({ ref }) => window.__LUNR__.en.store[ref])
  }

  search = event => {
    const query = event.target.value
    const results = this.getSearchResults(query)
    this.setState({ results, query })
  }
}

export default Search
