/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import Sidebar from "./Sidebar"

const Layout = ({ children }) => {
  return (
    <div className="flex flex-fill flex-col flex-1 overflow-hidden">
      <div className="flex flex-fill flex-row flex-1 overflow-hidden">
        <Sidebar className="hidden md:flex" />
        <main className="flex flex-fill flex-col flex-1 overflow-auto bg-white">
          {children}
        </main>
      </div>
      {/* <footer className="flex flex-col text-center p-2 text-color-accent bg-color-secondary">
        Sharpen Up! 2021
      </footer> */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
